/* General Header Layout */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 10;
    background-color: transparent;
    height: 10vh;
    padding-right: 8%;
    padding-left: 8%;
    transition: all 0.3s ease;
    /* Smooth transition for size and shadow */
}

/* Logo */
.logo {
    display: flex;
    align-items: center;
    position: relative;
    transition: margin-left 0.5s ease;
    animation: scaleUp 1s ease-in-out;
}

/* Logo size */
.logo img {
    height: 3em;
    width: auto;
    transition: transform 0.4s ease-in-out;
}

.logo img:hover {
    transform: scale(1.02);
    /* Slight scale on hover */
}

/* Center nav-menu */
.nav-menu {
    display: flex;
    gap: 2vw;
    align-items: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    height: 0;
}

.menu-open .nav-menu {
    opacity: 1;
    height: auto;
    transform: translateX(-50%);
}

/* Nav links */
.nav-menu a {
    color: white;
    text-decoration: none;
    font-weight: bold;
    transition: color 0.4s ease, transform 0.4s ease;
}

.nav-menu a:hover {
    color: #FF8000;
    transform: scale(1.02);
}

/* Dropdown container */
.dropdown {
    position: relative;
    display: inline-block;
    cursor: pointer;
    color: white;
    font-weight: bold;
    font-size: 1em;
    transition: color 0.3s ease;
}

.dropdown::before {
    content: '';
    position: absolute;
    top: 100%;
    /* Place just below the trigger */
    left: 0;
    width: 100%;
    height: 10px;
    /* Adjust as per the gap */
    background: transparent;
    /* Invisible */
    z-index: 1;
}


/* Dropdown content */
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #222;
    width: 220px;
    border-radius: 12px;
    padding: 10px 0;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.4);
    top: calc(100% + 10px);
    left: 0;
    z-index: 100;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-10px);
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out, visibility 0.5s ease-in-out;
}

/* Show dropdown on hover or open state */
.dropdown:hover .dropdown-content,
.dropdown-open .dropdown-content {
    display: block;
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
}

/* Dropdown links */
.dropdown-content a {
    display: block;
    padding: 10px 15px;
    color: white;
    font-size: 0.9rem;
    text-decoration: none;
    transition: background-color 0.3s ease, color 0.3s ease;
    border-radius: 8px;
    margin: 5px 10px;
}

.dropdown-content a:hover {
    background-color: rgba(255, 128, 0, 0.8);
    color: #222;
    transform: translateX(5px);
}

/* Sticky Header with Subtle Shadow */
.sticky-header {
    position: fixed;
    z-index: 100;
    box-shadow: 0 4px 10px rgb(0, 0, 0);
    height: 8vh;
    backdrop-filter: blur(4px);
    background-color: rgba(0, 0, 0, 0.6);
    transition: all 0.5s ease-in-out;
}

/* Sticky Logo Adjustments */
.sticky-header .logo img {
    height: 2.5em;
    transition: height 0.3s ease;
}


/* Mobile Menu styling*/

/* Mobile Menu */
.mobile-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.95);
    z-index: 9999;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 1rem;
    overflow-y: auto;
    padding-top: 4rem;
    /* Adjust this to control the gap from the top */
}

/* Adjust the trigger (Leistungen) styling */
.dropdown-trigger-mobile {
    color: white;
    text-decoration: none;
    font-weight: bold;
    font-size: 1rem;
    padding: 0px 15px;
    margin-top: 5vh;
    /* Add spacing to the trigger */
    text-align: left;
    /* Align text to the left */
    width: 100%;
    /* Add separator line below */
    transition: color 0.2s ease-in-out, transform 0.2s ease-in;
}

/* Trigger hover effect */
.dropdown-trigger-mobile:hover {
    color: #ff8000;
}

.mobile-menu a {
    color: white;
    text-decoration: none;
    font-weight: bold;
    transition: color 0.4s ease, transform 0.4s ease;
    font-size: 1rem;
}

/* Mobile Dropdown trigger */
.mobile-dropdown-links {
    display: none;
    flex-direction: column;
    align-items: center;
    text-align: left;
    gap: 1rem;
    margin-top: 1rem;
}

/* Hover effect for links */
.mobile-dropdown-links a:hover {
    background-color: rgba(255, 128, 0, 0.8);
    /* Highlight on hover */
    color: #222;
    /* Change text color on hover */
}

.mobile-dropdown-links.open {
    display: flex;
}


/* Mobile Menu Icon */
.mobile-menu-icon {
    position: absolute;
    top: 1rem;
    right: 1rem;
    color: white;
    font-size: 2rem;
    border: none;
    background: none;
    cursor: pointer;
    z-index: 10000;
    transition: transform 0.3s ease;
}

.mobile-menu-icon:hover {
    transform: scale(1.2);
}

.mobile-menu-logo img {
    height: 2.5rem;
    /* Adjust size as needed */
    width: auto;
    transition: transform 0.4s ease-in-out;
}

/* Mobile CTA */
/* Mobile Menu styling*/

/* Mobile Menu */
.mobile-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.95);
    z-index: 9999;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    overflow-y: auto;
    padding-top: 4rem;
    /* Adjust this to control the gap from the top */
}

/* Adjust the trigger (Leistungen) styling */
.dropdown-trigger-mobile {
    color: white;
    text-decoration: none;
    font-weight: bold;
    font-size: 1rem;
    padding: 0px 15px;
    /* Add spacing to the trigger */
    text-align: left;
    /* Align text to the left */
    width: 100%;
    /* Add separator line below */
    transition: color 0.2s ease-in-out, transform 0.2s ease-in;
}

/* Trigger hover effect */
.dropdown-trigger-mobile:hover {
    color: #ff8000;
}

.mobile-menu a {
    color: white;
    text-decoration: none;
    font-weight: bold;
    transition: color 0.4s ease, transform 0.4s ease;
    font-size: 1rem;
}

/* Mobile Dropdown trigger */
.mobile-dropdown-links {
    display: none;
    flex-direction: column;
    align-items: center;
    text-align: left;
    gap: 1rem;
    margin-top: 1rem;
}

/* Hover effect for links */
.mobile-dropdown-links a:hover {
    background-color: rgba(255, 128, 0, 0.8);
    /* Highlight on hover */
    color: #222;
    /* Change text color on hover */
}

.mobile-dropdown-links.open {
    display: flex;
}


/* Mobile Menu Icon */
.mobile-menu-icon {
    position: absolute;
    top: 1rem;
    right: 1rem;
    color: white;
    font-size: 2rem;
    border: none;
    background: none;
    cursor: pointer;
    z-index: 10000;
    transition: transform 0.3s ease;
}

.mobile-menu-icon:hover {
    transform: scale(1.2);
}

.mobile-menu-logo img {
    height: 2.5rem;
    /* Adjust size as needed */
    width: auto;
    transition: transform 0.4s ease-in-out;
}

/*Mobile menu CTA*/
/* Button container for "Ruf uns an" and "Termin" */
.mobile-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

/* Button styling */
.mobile-button {
    border: 2px solid;
    border-radius: 15px;
    transition: background-color 0.3s ease, color 0.3s ease;
    text-align: center;
    width: calc(45% - 0.2rem);
    background-color: rgba(255, 128, 0, 0.8);
    /* Adjust width for two buttons in one row */
}

/* "Ruf uns an" button styling */
.mobile-button-call {
    background-color: transparent;
    padding: 8px 45px;
}

.mobile-button-call:hover {
    background-color: var(--main-color);
    /* Highlight background on hover */
    color: white;
    /* Change text color on hover */
}

/* "Termin" button styling */
.mobile-button-termin {
    justify-content: center;
    font-size: 1rem !important;
}


.mobile-nav-trustpilot{
    margin-top: 5vh;
}


@media (max-width: 768px) {
    .desktop {
        display: none;
    }

    .mobile-menu-icon {
        display: block;
    }

    .nav-menu {
        display: none;
    }
}

@media (max-width: 768px) {
    .logo img {
        height: 1.5em;
        width: auto;
        transition: transform 0.4s ease-in-out;
    }
}

/* Animations */
@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes scaleUp {
    0% {
        transform: scale(0.95);
    }

    100% {
        transform: scale(1);
    }
}