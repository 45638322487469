/* Form Styling (Preserved Original) */

.override-small-title{
   margin-bottom: 1.5rem;
}

.cbf-form {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   width: 100%;
   margin-top: 20px;
}

.cbf-input-wrapper {
   display: flex;
   align-items: center;
   background-color: #ffffff;
   border-radius: 50px;
   box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
   overflow: hidden;
   width: 100%;
   max-width: 600px;
   margin-bottom: 20px;
}

.cbf-input-field {
   flex: 1;
   background-color: #ffffff;
   color: #000000;
   border: none;
   font-size: 1em;
   padding: 15px;
   outline: none;
}

.cbf-submit-button {
   background: linear-gradient(90deg, #FF2B00, #FF8000);
   color: #ffffff;
   font-weight: bold;
   font-size: 1em;
   text-transform: uppercase;
   border: none;
   cursor: pointer;
   padding: 15px 25px;
   border-radius: 0 50px 50px 0;
   box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
   transition: background 0.3s ease;
}

.cbf-submit-button:hover {
   background: linear-gradient(90deg, #e02000, #ff7a00);
}


/* Fade-In Animation */
@keyframes fadeIn {
   from {
      opacity: 0;
      transform: translateY(-20px);
   }

   to {
      opacity: 1;
      transform: translateY(0);
   }
}

.fade-in {
   animation: fadeIn 0.5s ease-in-out;
}

/* Pulse Animation for Error Message */
@keyframes pulse {
   0% {
      transform: scale(1);
      opacity: 1;
   }

   50% {
      transform: scale(1.05);
      opacity: 0.8;
   }

   100% {
      transform: scale(1);
      opacity: 1;
   }
}

.cbf-error-message {
   color: red;
   font-size: 1rem;
   text-align: center;
   margin-top: 10px;
   animation: pulse 1.5s infinite;
}

/* Popup Styling */
.cbf-popup {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background: rgba(0, 0, 0, 0.7);
   /* Darkened background */
   backdrop-filter: blur(8px);
   /* Enhanced blur effect */
   display: flex;
   align-items: center;
   justify-content: center;
   z-index: 1000;
}

.cbf-popup-content {
   text-align: center;
   padding: 25px;
   border-radius: 15px;
}

.cbf-popup-gif {
   max-width: 100%;
   height: auto;
   margin-top: 15px;
}

/* Waving Hand Animation */
.wave {
   display: inline-block;
   animation: wave 2s infinite;
}

@keyframes wave {
   0% {
      transform: rotate(0deg);
   }

   10% {
      transform: rotate(14deg);
   }

   20% {
      transform: rotate(-8deg);
   }

   30% {
      transform: rotate(14deg);
   }

   40% {
      transform: rotate(-4deg);
   }

   50% {
      transform: rotate(10deg);
   }

   100% {
      transform: rotate(0deg);
   }
}

.grecaptcha-badge {
   visibility: hidden;
}