/* Footer Base */
.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 20px;
    background-color: var(--secondary-bg-color);
    color: var(--primary-text-color);
}

/* Footer Sections */
.footer-section {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
}

/* Left Section: Trustpilot */
.footer-left {
    text-align: left;
}

/* Center Section: Logo */
.footer-center {
    text-align: center;
}

.footer-logo {
    max-width: 150px;
    height: auto;
    transition: transform 0.4s ease-in-out;
}

/* Scale effect on logo hover */
.footer-logo:hover {
    transform: scale(1.02);
    /* Slight scale on hover */
}

/* Right Section: Links */
.footer-right {
    text-align: right;
    display: flex;
    gap: 15px;
    /* Space between links */
}

.footer-right a {
    text-decoration: none;
    color: var(--primary-text-color);
    font-weight: bold;
    transition: color 0.3s;
}

.footer-right a:hover {
    color: var(--accent-color);
}

/* Responsive Design */
@media (max-width: 768px) {
    .footer {
        flex-direction: column;
        align-items: center;
    }

    .footer-section {
        width: 100%;
        text-align: center;
        margin: 10px 0;
    }

    .footer-right {
        flex-direction: column;
        gap: 5px;
        /* Reduced spacing for stacked links */
    }
}