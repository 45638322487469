/* General Container Styling */
.contact-details-container {
   display: flex;
   flex-wrap: wrap;
   justify-content: space-between;
   align-items: flex-start;
   max-width: 1200px;
   margin: 0 auto;
   padding: 2rem;
   background-color: #1a1a1a;
   /* Match your site's theme */
   border-radius: 10px;
   box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
   color: #fff;
   /* Adjust text color for contrast */
}

/* Contact Details Section */
.contact-details-container .contact-title {
   font-size: 2rem;
   font-weight: bold;
   margin-bottom: 1rem;
   text-align: left;
   color: #ffcc00;
   /* Accent color */
   flex: 1 1 100%;
   /* Full width for title */
}

.contact-benefits {
   list-style: none;
   padding: 0;
   margin: 0 0 1.5rem 0;
   line-height: 1.8;
   color: #fff;
}

.contact-highlight {
   color: #ffcc00;
   font-weight: bold;
   margin-bottom: 1rem;
}

.contact-description {
   margin-bottom: 2rem;
}

.contact-person {
   display: flex;
   align-items: center;
   margin-bottom: 2rem;
}

.contact-image {
   width: 80px;
   height: 80px;
   border-radius: 50%;
   margin-right: 1rem;
   box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.contact-info {
   text-align: left;
}

.contact-name {
   font-size: 1.2rem;
   font-weight: bold;
}

.contact-phone,
.contact-email {
   font-size: 1rem;
}

/* Calendly Inline Embed */
.calendly-container {
   flex: 1;
   min-width: 320px;
   max-width: 600px;
   height: auto;
   border: 2px solid #ffcc00;
   /* Add border to match design */
   border-radius: 10px;
   overflow: hidden;
   box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
}

/* Layout Adjustments */
.contact-details-container>* {
   flex: 1 1 calc(50% - 2rem);
   margin: 1rem;
}

/* Responsive Design */
@media (max-width: 768px) {
   .contact-details-container {
      flex-direction: column;
      align-items: center;
   }

   .calendly-container,
   .contact-details-container>* {
      flex: 1 1 100%;
   }

   .contact-image {
      width: 60px;
      height: 60px;
   }
}