/* Hero Section Styling */
.hero-section {
    position: relative;
    overflow: hidden;
    height: 100vh !important;
    max-width: none !important;
}

/* Video Background */
.background-video {
    position: absolute;
    object-fit: cover;
    width: 100vw;
    height: 100vh !important;
    opacity: 0.5;
    z-index: -1;
    filter: saturate(1.4) blur(5px);
    background-color: black;
}

.hero-content {
    width: 850px;
}

.hero-content h1 {
    line-height: 1;
    margin-bottom: 20px;
}

.hero-content p {
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 20px;
}

/* Small text container below the button */
.hero-small-text {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4rem;
    margin-top: 1rem;
    font-size: var(--small-text-size);
    color: var(--secondary-text-color);
}

/* TRUST Section */

.video-box {
    margin-top: -20vh;
    width: 900px;
    max-width: 90%;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
    border-radius: 15px;
    overflow: hidden;
}

.video-box video {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 15px;
}

/* WHY section */

.why-section{
    margin-bottom: 20vh;
}

.why-content {
    width: 750px;
}

.why-blocks {
    display: flex;
    justify-content: center;
    gap: 50px;
    flex-wrap: wrap;
    margin-top: 5vh;
}

.why-block {
    background: linear-gradient(90deg, #FF8000, #FF2B00);
    color: #fff;
    text-align: center;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    flex: 1 1 250px;
    min-width: 300px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    /* Smooth hover transition */
}

.why-block h3 {
    font-size: 24px;
    margin-bottom: 10px;
    text-shadow: 2px 2px 8px #000;
}

.why-block p {
    font-size: 16px;
    line-height: 1.4;
}

/* Hover Effect */
.why-block:hover {
    transform: scale(1.05);
    /* Slight enlargement */
    box-shadow: 0 0 15px rgb(255, 128, 0);
    /* Glowing shadow */
}

.why-cta-container {
    margin-top: 5vh;
    padding: 30px;
    background: #0e0e0e;
    /* Dark background for contrast */
    border-radius: 20px;
    /* Smooth, rounded corners */
    box-shadow: 0 0 15px rgb(255, 128, 0);
    text-align: center;
    /* Center align all content */
    width: 100%;
    /* Restrict maximum width for better layout */
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.why-cta-container:hover {
    transform: scale(1.05);
    /* Slight enlargement */
    box-shadow: 0 0 15px rgb(255, 128, 0);
    /* Glowing shadow */
}

.why-title {
    font-size: 40px !important;
    margin-bottom: -5px !important;
}

/* Responsive Design */
@media (max-width: 768px) {
    .why-blocks {
        flex-direction: column;
        align-items: end;
        gap: 30px;
    }

    .why-block h3{
        line-height: 24px;
    }
    
    .why-block-left{
        align-self: start !important;
    }

    .why-block {
        max-width: 80%;
        flex: 1 1 150px;
    }

    .why-title {
        font-size: 25px !important;
    }

    .why-cta-container{
        overflow: visible;
    }
}


/* END of WHY section */


/* START OF Categorie services */

/* we boxes */

.we-create{
    max-width: none !important;
}
/* We Create Section Layout */
.we-box-row {
    display: flex;
    justify-content: space-between;
    /* Push left and right items to edges */
    align-items: stretch;
    /* Stretch height of items to match row */
    /* Dynamic spacing between elements */
    gap: 100px;
    /* Full row width */
    margin-bottom: 5rem;
}

.we-box-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* Stick items to top and bottom */
    gap: 1rem;
    /* Adds spacing for extra items in the column */
}

/* Box Styling */
.we-box-medium {
    position: relative;
    width: 400px;
    /* Medium width */
    height: 400px;
    /* Uniform height */
    box-shadow: 0 0 10px rgb(255, 128, 0);
    /* Glow shadow */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* Start content at the top */
    padding: 20px;
    border-radius: 15px;
    text-align: left;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.we-box-slim {
    width: 400px;
    /* Slim width, matching medium */
    height: 180px;
    /* Slim height */
    box-shadow: 0 0 10px rgb(255, 128, 0);
    /* Glow shadow */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* Start content at the top */
    padding: 20px;
    border-radius: 15px;
    text-align: left;    
    transition: transform 0.3s ease, box-shadow 0.3s ease;

}

.we-box-large {
    width: 900px;
    /* Large width */
    height: 400px;
    /* Uniform height */
    box-shadow: 0 0 15px rgb(255, 128, 0);
    /* Glow shadow */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* Start content at the top */
    border-radius: 15px;
    text-align: left;
    transition: transform 0.3s ease, box-shadow 0.3s ease;

}

.we-box-extralarge {
    width: 1400px;
    /* Large width */
    height: 400px;
    /* Uniform height */
    box-shadow: 0 0 15px rgb(255, 128, 0);
    /* Glow shadow */
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* Start content at the top */
    border-radius: 15px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;

}


.we-box-slim:hover,
.we-box-medium:hover,
.we-box-large:hover,
.we-box-extralarge:hover {
    transform: scale(1.01);
    /* Slight enlargement */
    box-shadow: 0 0 20px rgba(255, 128, 0, 0.8);
    /* Glowing shadow with transparency */
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    /* Smooth transition for both effects */
}

/* Title inside the box */
.we-box-title {
    font-size: 1.8rem;
    /* Font size for title */
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 1rem;
    /* Space between title and text */
    color: var(--primary-text-color);
    /* Title color */
}

/* Text inside the box */
.we-box-text {
    display: inline !important;
    font-size: 1rem;
    /* Font size for text */
    line-height: 1.5;
    color: var(--secondary-text-color);
    /* Text color */
    font-weight: 100;
}

.we-box-text-small{
    font-size: 14px;
}

/* Positioning Classes */
.we-box-left {
    margin-right: auto;
    /* Pushes the box to the far left */
}

.we-box-center {
    margin: 0 auto;
    /* Centers the box horizontally */
}

.we-box-right {
    margin-left: auto;
    /* Pushes the box to the far right */
}

/* Image inside the box */
.we-box-image {
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 300px;
    /* Adjust as needed */
    height: auto;
    /* Maintain aspect ratio */
    border-radius: 0 0 15px 0;
    /* Slightly rounded corners for a polished look */
    z-index: 10;
}


/* Container for logo rows */
.we-box-image-container {
    display: flex;
    flex-direction: column;
    /* Stack rows vertically */
    justify-content: center;
    /* Center rows vertically */
    align-items: center;
    /* Center the rows horizontally */
    gap: 20px;
    /* Space between rows */
    margin-top: 20px;
    /* Space above the image container */
}

/* Row of logos */
.we-box-image-row {
    display: flex;
    justify-content: center;
    /* Center images in each row */
    gap: 20px;
    /* Space between images in a row */
}

.we-box-optimize{
    height: 200px;
}

.we-box-logo-images {
    width: 70px;
    /* Make the image fill the grid cell */
    height: auto;
    /* Maintain aspect ratio */
    border-radius: 10px;
    /* Add rounded corners */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    /* Add subtle shadow */
}

/* Video inside the box */
.we-box-video {
    width: 100%;
    height: 100%;
    border-radius: 15px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    /* Subtle shadow for the video */
    object-fit: fill;
    padding: 0;
    /* Ensures the video fills the box */
    pointer-events: none;
    /* Prevents any user interaction with the video */
}

/* Responsive Design */
@media (max-width: 768px) {
    .we-box-row {
        flex-direction: column;
        /* Stack items vertically */
        align-items: center;
        /* Center items for smaller screens */
        gap: 2rem;
        /* Adjust spacing for mobile */
    }

    .we-box-medium,
    .we-box-large,
    .we-box-slim {
        width: 100%;
        /* Full width on smaller screens */
        height: auto;
        /* Adjust height dynamically */
        text-align: center;
        /* Center text alignment */
    }

    .we-box-title,
    .we-box-text {
        text-align: center;
        /* Center text alignment for mobile */
    }
}

/* We Create Section */

/* We market Section */

/* Row Div for Title and GIFs */
.we-box-header-row {
    display: flex;
    align-items: center;
    /* Align items vertically */
    justify-content: space-between;
    /* Distribute space evenly */
    gap: 1rem;
}


/* GIF Container */
.we-box-inline-img {
    display: flex;
    gap: 0.5rem;
    /* Add spacing between the GIFs */
}

/* GIF Icons */
.gif-icon {
    width: 40px;
    height: 40px;
    object-fit: contain;
    /* Maintain aspect ratio */
}

.we-box-inline-image{
    width: 50px;
    height: auto;
    object-fit: cover;
        /* Maintain aspect ratio */
}

.we-box-large-gif{
    width: 150px;
}


/* WE Optimize*/
.we-box-row-override{
    margin-bottom: 0 !important;
}

/* Override for GIF container */
.we-optimize-image-container {
    width: 30%;
    /* Fixed 30% of parent container */
    display: flex;
    align-items: center;
    /* Center vertically */
    justify-content: center;
}

/* GIF Styling */
.we-box-image-left {
    width: 300px;
    height: auto;
    /* Vertically centers the GIF */
    border-radius: 15px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

/* Override for Content Container */
.we-optimize-content-container {
    width: 70%;
    /* Fixed 70% of parent container */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* Evenly distribute content vertically */
    gap: 20px;
    /* Spacing between header and rows */
}

/* Row of Texts */
.we-optimize-row {
    display: flex;
    justify-content: space-between;
    /* Align rows evenly */
    gap: 20px;
    /* Add spacing between the blocks */
}

.we-optimize-bottom{
    margin-top: 5vh;
    margin-bottom: 20vh;
}

/* Right Side: Content */
.we-optimize-content {
    width: 70%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    /* Add space between title and text rows */
    
}

/* Header within Content Container */
.we-optimize-title {
    text-align: center;
    /* Space below the title */
    font-size: 1.8rem;
    font-weight: bold;
    text-transform: uppercase;
    color: var(--primary-text-color);
}

/* Individual Text Block */
.we-optimize-text-block {
    flex: 1;
    /* Make all text blocks equal width */
    padding: 10px;
    text-align: left;
}

.we-optimize-text-row h4 {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.we-optimize-text-block p {
    font-size: 0.9rem;
    margin: 0;
    line-height: 1.2;
    color: var(--secondary-text-color);
}

/* Mobile-Specific We Box Styles */
.we-box-row-mobile {
    display: flex;
    flex-wrap: nowrap;
    /* Ensure items stay in a single row */
    overflow-x: auto;
    /* Enable horizontal scrolling */
    gap: 1rem;
    /* Add spacing between boxes */
    padding: 1rem;
    /* Add padding for mobile view */
    scroll-snap-type: x mandatory;
    /* Enable snapping */
    scrollbar-width: thin;
    /* Optional: Customize scrollbar for Firefox */
}

/* Optional: Customize scrollbar for Webkit browsers */
.we-box-row-mobile::-webkit-scrollbar {
    height: 8px;
    /* Set height of horizontal scrollbar */
}

.we-box-row-mobile::-webkit-scrollbar-thumb {
    background-color: rgba(255, 128, 0, 0.8);
    /* Set color of the scrollbar thumb */
    border-radius: 4px;
    /* Round the edges of the scrollbar thumb */
}

.we-box-row-mobile::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
    /* Set color of the scrollbar track */
}

/* Individual Boxes */
.we-box-row-mobile .we-box-medium {
    flex: 0 0 auto;
    /* Prevent boxes from shrinking or stretching */
    scroll-snap-align: center;
    /* Snap each box to the center */
    width: 80%;
    /* Adjust width for smaller screens */
    max-width: 300px;
    /* Limit the width */
    height: auto;
    /* Adjust height dynamically */
    box-shadow: 0 0 10px rgb(255, 128, 0);
    /* Mobile glow effect */
    padding: 20px;
    /* Add padding inside the box */
    border-radius: 15px;
    /* Rounded corners */
    text-align: center;
    /* Center text alignment */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* Hover Effects */
.we-box-row-mobile .we-box-medium:hover {
    transform: scale(1.05);
    /* Slight scale on hover */
    box-shadow: 0 0 15px rgba(255, 128, 0, 0.8);
    /* Enhance shadow on hover */
}

/* Responsive Design */
@media (max-width: 768px) {
    .we-optimize-row {
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }

    .we-optimize-image,
    .we-optimize-content {
        width: 100%;
    }

    .we-optimize-title,
    .we-optimize-text-row {
        text-align: center;
    }

    /*TEST*/

    .we-box-medium {
        height: 500px !important;
        min-width: 350px !important;
    }

    .we-box-slim {
        height: 220px !important;
        min-width: 350px !important;
    }

    .we-box-column {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 60px;
    }

    .we-box-large {
        height: 220px !important;
        min-width: 380px !important;
    }

    .we-box-extralarge {
        min-width: 1000px !important;
        height: 220px !important;
    }

    .we-box-video {
        height: 220px !important;
        min-width: 380px;
    }

    .we-box-title {
        font-size: 1.5rem;
        line-height: 20px;
    }

    .we-optimize-text-block{
        min-width: 150px !important;
    }

    .we-optimize-image-container{
        width: 100%;
    }
    

    /* Horizontal Scrolling for Mobile We Boxes */
    .horizontal-scroll-container {
        display: flex;
        overflow-x: auto;
        /* Enable horizontal scrolling */
        scroll-snap-type: x mandatory;
        /* Snap effect for scrolling */
        gap: 1rem;
        /* Add space between boxes */
        padding: 1rem;
        
    }

        /* Horizontal Scroll Content */
        .horizontal-scroll-content {
            display: flex;
            gap: 1rem;
            /* Space between text blocks */
            align-items: center;
            /* Align items vertically */
            scroll-snap-align: center;
            /* Snap items to the center */
            flex-wrap: nowrap;
            /* Ensure single-line scrolling */
        }

    .horizontal-scroll-container::-webkit-scrollbar {
        height: 1px;
        background-color: transparent;
        /* Set height for horizontal scrollbar */
    }

    .horizontal-scroll-container::-webkit-scrollbar-thumb {
        background-color: rgba(255, 128, 0, 0.8);
        /* Bright orange for the scrollbar thumb */
        border-radius: 4px;
        /* Rounded scrollbar edges */
    }

    .horizontal-scroll-container::-webkit-scrollbar-track {
        background: rgba(0, 0, 0, 0.1);
        /* Dim background for the scrollbar track */
    }

    /* Mobile-specific box styling */
    .horizontal-scroll-container .we-box-medium,
    .horizontal-scroll-container .we-box-large,
    .horizontal-scroll-container .we-box-slim {
        flex: 0 0 auto;
        /* Prevent boxes from shrinking */
        scroll-snap-align: center;
        /* Align boxes to the center when scrolling */
        width: 80%;
        /* Adjust box width for mobile */
        max-width: 300px;
        /* Ensure boxes don't exceed the set width */
        height: auto;
        /* Adjust height dynamically */
        text-align: center;
        /* Center text inside the box */
        border-radius: 15px;
        /* Rounded corners */
        box-shadow: 0 0 10px rgba(255, 128, 0, 0.8);
        /* Glowing box shadow for mobile */
        transition: transform 0.3s ease, box-shadow 0.3s ease;
    }

    /* Hover effect for boxes on mobile */
    .horizontal-scroll-container .we-box-medium:hover,
    .horizontal-scroll-container .we-box-large:hover,
    .horizontal-scroll-container .we-box-slim:hover {
        transform: scale(1.05);
        /* Slight zoom-in effect */
        box-shadow: 0 0 20px rgba(255, 128, 0, 0.8);
        /* Enhanced shadow on hover */
    }

    /* Adjust the text alignment for mobile */
    .we-box-title,
    .we-box-text {
        text-align: center;
        /* Center align text for mobile */
    }

    /* Adjust rows for stacking on smaller screens */
    .we-box-row {
        flex-direction: row;
        /* Enable horizontal layout for scrolling */
        align-items: flex-start;
        /* Align items to the top */
    }

    /* Add more spacing for text in slim boxes */
    .we-box-slim p {
        margin-top: 0.5rem;
    }

    /* Mobile-specific video adjustments */
    .we-box-video {
        width: 100%;
        height: auto;
        border-radius: 15px;
        object-fit: cover;
        /* Cover the container dynamically */
    }

    /* Small adjustments for the second section */
    .we-box-row-mobile {
        margin-top: 2rem;
        /* Add spacing between rows */
    }
}


/* END OF Categorie services */

/* Contact Section */

.contact-section .button.extralarge{
    padding: 20px 200px;
}

.why-content .button.extralarge{
    padding: 20px 150px;
}

.gif-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(2px);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.gif-container img {
    max-width: 90%;
    max-height: 90%;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
    animation: fadeIn 0.3s ease-in-out;
}


/* Keyframes for animations */
@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .hero-content {
        width: 300px;
    }

    .hero-small-text {
    display: grid;
    margin-top: 1rem;
    gap: 0.3rem;
    font-size: var(--small-text-size);
    color: var(--secondary-text-color);
    }

    .video-box {
        max-width: 90%;
    }

    .trust-section{
        min-height: 0vh !important;
    }

    .why-content {
        width: fit-content;
        padding: 2rem 1.5rem;
    }

}