.legal-container {
   margin-top: 10vh;
   padding: var(--section-margin);
   display: flex;
   flex-direction: column;
   text-align: left;
   justify-content: center;
   max-width: 1200px;
   margin: 0 auto;
   color: var(--primary-text-color);
   margin-top: 10vh;
}


.legal-section {
   margin-top: 5vh;
   text-align: left !important;
   display: flex !important;
   flex-direction: column !important;
   align-items: flex-start !important;
}

.legal-section ul {
   list-style-position: inside;
   /* Ensures bullets/numbers align with the text */
   margin: 0;
   padding: 0;
}

.legal-section ul li {
   text-align: left;
   /* Explicitly aligns the text inside each list item */
   margin-bottom: 0.5rem;
   /* Optional: Adds spacing between list items */
}