/* ### GLOBAL STYLES ### */
:root {
  /* Colors */
  --primary-bg-color: #2a2a2a;
  --secondary-bg-color: #0e0e0e;
  --primary-text-color: #e9e9e9;
  --secondary-text-color: #e7e7e7;
  --accent-color: #ff8000;
  --primary-gradient-color: linear-gradient(90deg, #ff8000, #ff2b00);
  /* Fixed gradient syntax */

  /* Typography */
  --font-family-main: 'OSWALD', 'Roboto', sans-serif;
  
  /* Font sizes in rem for scalability */
  --large-title-size: 6.25rem;
  /* 100px */
  --medium-title-size: 3.75rem;
  /* 60px */
  --small-title-size: 2.5rem;
  /* 40px */
  --extralarge-text-size: 1.6rem;
  /*50px*/
  --large-text-size: 1.5625rem;
  /* 25px */
  --medium-large-text-size: 1.375rem;
  /* 22px */
  --medium-text-size: 1.25rem;
  /* 20px */
  --small-text-size: 0.9375rem;
  /* 15px */

  /* Font weights */
  --font-weight-bold: 900;
  --font-weight-medium: 500;
  --font-weight-light: 300;

  /* Spacing in rem for scalability */
  --section-margin: 2.5rem;
  /* 40px */
  --title-margin-bottom: 2.5rem;
  /* 40px */

  /* Sizes */
  --min-height-full: 100vh;
  --header-footer-width: 100%;

  /* Border Radius */
  --border-radius: 15px;

  flex: 1;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  :root {
    /* Font sizes */
    --large-title-size: 4.5rem;
    /* 72px */
    --medium-title-size: 3rem;
    /* 48px */
    --small-title-size: 2rem;
    /* 32px */
    --large-text-size: 1.25rem;
    /* 20px */
    --medium-text-size: 1.125rem;
    /* 18px */
    --small-text-size: 0.875rem;
    /* 14px */

    /* Spacing */
    --section-margin: 2rem;
    /* 32px */
    --title-margin-bottom: 2rem;
    /* 32px */
  }
}

@media (max-width: 480px) {
  :root {
    /* Font sizes */
    --large-title-size: 2.5rem;
    /* 40px */
    --medium-title-size: 1.875rem;
    /* 30px */
    --small-title-size: 1.25rem;
    /* 20px */
    --large-text-size: 0.8125rem;
    /* 13px */
    --medium-text-size: 0.75rem;
    /* 12px */
    --small-text-size: 0.6875rem;
    /* 11px */

    /* Spacing */
    --section-margin: 0.75rem;
    /* 12px */
    --title-margin-bottom: 0.75rem;
    /* 12px */
  }
}

/* Reset */
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: var(--secondary-bg-color);
  font-family: var(--font-family-main);
  color: var(--secondary-text-color);
  min-height: var(--min-height-full);
}



h1, p, a{
  text-decoration: none;
  color: var(--primary-text-color);
}

/* ### TITLE STYLES ### */
.large-title,
.medium-title,
.small-title {
  font-weight: var(--font-weight-bold);
  color: var(--primary-text-color);
  text-transform: uppercase;
  margin-bottom: var(--title-margin-bottom);
}

.large-title, h1 {
  font-size: var(--large-title-size);
  font-weight: var(--font-weight-bold);
  letter-spacing: -.05em;
  text-shadow: 2px 2px 8px #2a2a2a;
    /* Adds depth and prominence */}


.medium-title, h2 {
  font-size: var(--medium-title-size);
  text-shadow: 4px 4px 8px #000000; /* Adds depth and prominence */
  line-height: 50px;
  letter-spacing: -.05em;
} 


.small-title, h3 {
  font-size: var(--small-title-size);
}

.large-text {
  font-size: var(--large-text-size);
}

.medium-text, p {
  font-size: var(--medium-text-size);
  font-weight: var(--font-weight-medium);
}

.small-text {
  font-size: var(--small-text-size);
}

.bold-text {
  font-size: var(--medium-text-size);
  font-weight: var(--font-weight-bold);
}

.special-text {
  font-size: var(--medium-large-text-size);
  font-weight: var(--font-weight-bold);
  text-shadow: 4px 4px 8px #000000;
  
}

.link-hover:hover{
  color: var(--accent-color);
  transition-duration: 300ms;
}

/* ### END OF TITLE STYLES ### */

/* ### SECTION STYLES ### */
.section-style {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* Center horizontally */
  text-align: center;
  min-height: 70vh;
  /* Adaptable height */
  max-width: 1200px;
  /* Restrict width */
  margin: 0 auto;
  padding: 5rem 1.5rem;
  /* Add breathing space */
}

.section-style-focus {
  max-width: 1440px;
  /* For hero/CTAs */
}

.section-style-detail {
  max-width: 1280px;
  /* For feature/details */
}

/* ### END OF SECTION STYLES ### */



/* ### ANIMATIONS ### */
/* Base Animation */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: var(--fade-transform-start);
  }

  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}

/* Smooth Animations with Adjustable Speeds */

.fade-in {
  animation: fadeIn var(--animation-duration, 0.8s) cubic-bezier(0.22, 1, 0.36, 1);
  animation-delay: var(--animation-delay, 0.1s);
  animation-fill-mode: both;
}

.fade-in-left {
  --fade-transform-start: translateX(-100px);
  animation: fadeIn var(--animation-duration, 0.8s) cubic-bezier(0.22, 1, 0.36, 1);
  animation-delay: var(--animation-delay, 0.1s);
  animation-fill-mode: both;
}

.fade-in-right {
  --fade-transform-start: translateX(100px);
  animation: fadeIn var(--animation-duration, 0.8s) cubic-bezier(0.22, 1, 0.36, 1);
  animation-delay: var(--animation-delay, 0.1s);
  animation-fill-mode: both;
}

.fade-in-top {
  --fade-transform-start: translateY(-100px);
  animation: fadeIn var(--animation-duration, 0.8s) cubic-bezier(0.22, 1, 0.36, 1);
  animation-delay: var(--animation-delay, 0.1s);
  animation-fill-mode: both;
}

.fade-in-bottom {
  --fade-transform-start: translateY(100px);
  animation: fadeIn var(--animation-duration, 0.8s) cubic-bezier(0.22, 1, 0.36, 1);
  animation-delay: var(--animation-delay, 0.1s);
  animation-fill-mode: both;
}

/* Speed Modifier Classes */
.animation-fast {
  --animation-duration: 0.5s;
}


.animation-slow {
  --animation-duration: 1.5s;
}

/* Delay Modifier Classes */
.animation-delay-short {
  --animation-delay: 0.3;
}

.animation-delay-long {
  --animation-delay: 0.5s;
}

.animation-delay-longer {
  --animation-delay: 0.7s;
}

.animation-delay-extreme{
  --animation-delay: 1s;
}


.hidden {
  opacity: 0;
  /* Hide initially */
  transform: var(--fade-transform-start);
  /* Start transform from your animation */
  animation: none;
  /* Disable animation while hidden */
}
/* ### END OF ANIMATIONS ### */


/* ### CONTAINERS ### */
header,
main {
  width: var(--header-footer-width);
}

main {
  flex: 1;
}

/* ### END OF CONTAINERS ### */

/* ### BUTTON STYLES ### */
.button {
  position: relative;
  /* Needed for the pseudo-element positioning */
  background: linear-gradient(to right, #ff2e04, #ff8000);
  color: var(--primary-text-color);
  border: none;
  border-radius: var(--border-radius);
  cursor: pointer;
  font-family: var(--font-family-main);
  font-weight: var(--font-weight-bold);
  text-transform: uppercase;
  transition: background-color 0.5s ease, transform 0.5s ease;
  margin: 10px;
  padding: 10px;
  overflow: hidden;
  /* Ensures the sheen effect stays within the button */
}

/* Hover and active states */
.button:hover {
  background: linear-gradient(to right, #e62700, #ee7700);
  transform: translateY(-2px);
}

.button:active {
  background-color: #cc6600;
  transform: translateY(0);
}

/* ### SEPARATE SHEEN CLASS ### */
.sheen::after {
  content: '';
  position: absolute;
  top: -50%;
  left: -100%;
  width: 200%;
  height: 200%;
  background: linear-gradient(to bottom,
      rgba(229, 172, 142, 0),
      rgba(255, 255, 255, 0.5) 50%,
      rgba(229, 172, 142, 0));
  transform: rotateZ(45deg);
  pointer-events: none;
  /* Prevents interaction with the pseudo-element */
  opacity: 0;
  /* Hidden by default */
  transition: none;
}

/* Activate the sheen on hover */
.sheen:hover::after {
  left: 100%;
  /* Move the sheen across */
  opacity: 1;
  /* Make it visible */
  transition: all 0.4s ease;
}

/* ### BUTTON SIZE VARIANTS ### */
.button.small {
  padding: 10px 45px;
  font-size: var(--medium-text-size);
  text-shadow: 2px 2px 8px #2a2a2a;
}

.button.medium {
  padding: 10px 20px;
  font-size: var(--medium-text-size);
  text-shadow: 2px 2px 8px #2a2a2a;
}

.button.large {
  padding: 15px 30px;
  font-size: var(--large-text-size);
  text-shadow: 2px 2px 8px #2a2a2a;
}

button.widelarge {
  padding: 15px 80px;
  font-size: var(--large-text-size);
  text-shadow: 2px 2px 8px #2a2a2a;
}

button.extralarge {
  padding: 20px 300px;
  font-size: var(--extralarge-text-size);
  text-shadow: 2px 2px 8px #2a2a2a;
}

button.thic {
  padding: 40px 100px;
  font-size: var(--extralarge-text-size);
  text-shadow: 2px 2px 8px #2a2a2a;
}



/* Prevent user Action */
 .not-selectable {
  user-select: none;
 }

 .non-draggable {
  -webkit-user-drag: none;
  pointer-events: none;
 }


 
 /* Mobile / Desktop Display*/

 /* Desktop Styles */
 @media (min-width: 769px) {
   .desktop {
     display: block;
     /* Show elements with this class on Desktop/Tablet */
   }

   .mobile {
     display: none;
     /* Hide elements with this class on Desktop/Tablet */
   }
 }

 /* Mobile Styles */
 @media (max-width: 768px) {
   .desktop {
     display: none;
     /* Hide elements with this class on Mobile */
   }

 }