/* ### Import Custom Font ### */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap');

/* ### Global Reset ### */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  max-width: 100vw;
  /* Prevent elements from exceeding the viewport width */
}

/* ### Base Defaults ### */
html,
body {
  overflow-x: hidden;
  /* Ensure vertical scrolling is enabled */
  /* Prevent horizontal scrolling globally */
  width: 100%;
  height: auto;
  /* Ensure full height layout consistency */
}

body {
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 16px;
  /* Base font size */
  line-height: 1.5;
  /* Comfortable line spacing */
  background-color: #fff;
  /* Set default background */
  color: #333;
  /* Default text color */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* ### Scrollbar Styling ### */
::-webkit-scrollbar {
  width: 12px;
  background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border-radius: 10px;
}

html {
  scrollbar-width: thin;
  scrollbar-color: #c1c1c1 #f1f1f1;
  /* Thumb and track colors */
}