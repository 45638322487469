.faq-container {
   max-width: 1000px !important; 
   margin: 0 auto;
   padding: 2rem;
   background-color: #2a2a2a;
   border-radius: 15px;
}

.faq-title {
   font-size: var(--medium-title-size);
   text-align: center;
   color: var(--primary-text-color);
   margin-bottom: 2rem;
}

.faq-items {
   display: flex;
   flex-direction: column;
   gap: 1rem;
}

.faq-item {
   padding: 1rem;
   background-color: #181818;
   border-radius: 10px;
   cursor: pointer;
   transition: background-color 0.4s ease;
   /* Slightly longer and smoother */
   user-select: none;
   /* Prevent text selection */
}

.faq-item:hover {
   background-color: #202020;
}

.faq-question {
   font-size: var(--medium-text-size);
   font-weight: var(--font-weight-medium);
   color: var(--accent-color);
}

.faq-answer {
   font-size: 1rem;
   font-weight: 400;
   color: #d3d3d3;
   line-height: 1.6;
   margin-top: 0.5rem;
   max-height: 0;
   /* Initial state */
   opacity: 0;
   /* Initial state */
   overflow: hidden;
   transition: max-height 0.6s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.6s ease;
}

.faq-item.active .faq-question {
   color: var(--primary-text-color);
}

.faq-item.active .faq-answer {
   max-height: 300px;
   /* Adjust to accommodate your longest content */
   opacity: 1;
}