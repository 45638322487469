/* ### Hero Buttons Section ### */
.hero-buttons {
    display: flex;
    justify-content: center;
    padding-top: 5vh;
    position: relative;
}

/* Input Field */
.input-field {
    background-color: #ffffff;
    color: #6c757d;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    padding: 20px;
    padding-left: 50px;
    /* Maintains the specific left padding */
    font-size: 1em;
    text-align: center;
    border-radius: 50px 0 0 50px;
    pointer-events: none;
    width: 70%;
    border: none;
}

/* Input Icon */
.input-icon {
    background-color: #ffffff;
    z-index: 1;
    padding-right: 10px;
}

/* Submit Button */
.submit-button {
    background: linear-gradient(90deg, #FF2B00, #FF8000);
    color: #fff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    font-weight: bold;
    font-size: 1em;
    text-transform: uppercase;
    border: none;
    cursor: pointer;
    border-radius: 0 50px 50px 0;
    width: 30%;
    transition: background 0.3s ease;
    /* Smooth hover effect */
}

/* Hover Effect */
.submit-button:hover {
    background: linear-gradient(90deg, #e02000, #ff7a00);
}

/* Limit the visible height of the iframe */
.calendly-calendar-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 450px;
    /* Adjust to fit only the calendar */
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

/* Style the iframe */
.calendly-calendar-container .calendly-inline-widget {
    position: absolute;
    top: -150px;
    /* Shift up to hide extra parts */
    left: 0;
    width: 100%;
    height: 700px;
    /* Keep full height for proper scrolling */
}


/* Calendly Inline Embed Container */
.calendly-inline-container {
    position: fixed;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
    width: 70%;
    max-width: 1000px;
    background: white;
    border-radius: 10px;
    z-index: 1000;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
    padding-top: 20px;
    /* Space for the close button */
    position: relative;
    /* Relative positioning for the close button */
    animation: fadeIn 0.5s ease-in-out;
    /* Smooth fade-in animation for inline embed */
}

/* Close Button for Inline Calendly */
.popup-close {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: none;
    font-size: 24px;
    font-weight: bold;
    color: #333;
    cursor: pointer;
    z-index: 1010;
    /* Ensure it stays above the iframe */
    transition: transform 0.2s ease, color 0.3s ease;
}

.popup-close:hover {
    transform: scale(1.2);
    /* Slight scale effect on hover */
    color: #ff8000;
    /* Highlight color on hover */
}

/* Yoda GIF Container */
.yoda-gif-container {
    position: fixed;
    bottom: 10%;
    right: 5%;
    cursor: pointer;
    z-index: 1000;
    animation: fadeIn 0.8s ease-in-out;
    /* Smooth fade-in animation */
}

/* Yoda GIF Styling */
.yoda-gif {
    width: 100px;
    height: auto;
    transition: transform 0.3s ease, opacity 0.3s ease;
    /* Smooth hover effect */
}

.yoda-gif:hover {
    transform: scale(1.1);
}

/* Style for the link inside CalendlyButtons */
.calendly-link {
    display: inline !important;
    color: var(--accent-color);
    cursor: pointer;
    font-weight: bold;
    /* Optional for emphasis */
}

/* Hover effect for the link */
.calendly-link:hover {
    display: inline !important;
    color: var(--hover-color);
    /* Adjust for hover state */
    text-decoration: none;
}


.calendly-inline-widget{
    overflow: hidden !important;
    height: auto;
    width: 100%;
}

.calendly-inline-widget iframe{
    overflow: hidden !important;
}

.calendly-inline-widget .side-panel{
    display: none !important}

/* ### Responsive Design ### */

/* Tablets (max-width: 768px) */
@media (max-width: 768px) {
    .hero-buttons {
        flex-direction: column;
        /* Stack input and button vertically */
        padding: 7px;
        gap: 10px;
        /* Consistent spacing between stacked items */
    }

    .input-field {
        width: 100%;
        padding: 10px;
        /* Adjust for smaller screens */
        padding-left: 50px;
        /* Keep left padding intact */
        font-size: 0.9em;
        /* Adjusted for responsiveness */
        border-radius: 50px;
        /* Fully rounded for smaller screens */
    }

    .input-icon {
        display: none;
        /* Hide the icon for mobile screens */
    }

    .submit-button {
        width: 100%;
        font-size: 0.9em;
        border-radius: 50px;
    }
}

/* Mobile Small (max-width: 480px) */
@media (max-width: 480px) {
    .input-field {
        padding: 8px;
        padding-left: 50px;
        /* Keep specific design requirement */
        font-size: 0.8em;
        /* Reduced font size for small screens */
    }

    .submit-button {
        font-size: 0.8em;
        padding: 8px;
    }
}

/* Animations */
@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}