/* Yoda GIF Container */
.yoda-gif-container {
   position: fixed;
   bottom: 10%;
   right: 5%;
   cursor: pointer;
   z-index: 1000;
   animation: fadeIn 0.8s ease-in-out;
}

/* Yoda GIF Styling */
.yoda-gif {
   width: 100px;
   height: auto;
   transition: transform 0.3s ease, opacity 0.3s ease;
}

.yoda-gif:hover {
   transform: scale(1.1);
}

/* Calendly Inline Embed */
.calendly-inline-container {
   position: fixed;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   width: 80%;
   height: 80%;
   background: white;
   border-radius: 10px;
   padding: 0 0;
   z-index: 1000;
   box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
   animation: fadeIn 0.5s ease-in-out;
   overflow: hidden;
}

/* Close Button */
.popup-close {
   position: absolute;
   top: 10px;
   right: 10px;
   border: none;
   background: none;
   font-size: 18px;
   cursor: pointer;
   z-index: 1001;
}

.popup-close:hover {
   transform: scale(1.2);
}

/* Prevent Scrolling */
.no-scroll {
   overflow: hidden;
   height: 100%;
}

/* Popup Overlay */
.popup-overlay {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background-color: rgba(0, 0, 0, 0.7);
   /* Semi-transparent background */
   z-index: 999;
   animation: fadeIn 0.3s ease-in-out;
   cursor: pointer;
}