.carousel-container {
    width: 100%;
    overflow: hidden;
    position: relative;
    /* Optional background for contrast */
    display: flex;
    justify-content: center;
    align-items: center;
}

.carousel-track {
    display: flex;
    animation: scroll 90s linear infinite;
    gap: 150px;
    /* Distance between logos */
}

.carousel-slide {
    flex-shrink: 0;
    /* Prevent logos from shrinking */
    width: auto;
    padding: 10px;
}

.carousel-logo {
    max-height: 150px;
    /* Adjust size of logos */
    object-fit: contain;
    /* Keep proportions */
    transition: transform 0.5s ease-in-out;
}

.carousel-logo:hover {
    transform: scale(1.2);
    /* Slight zoom effect on hover */
}

/* Keyframe for infinite scrolling */
@keyframes scroll {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-100%);
    }
}