.starfield-fade-enter {
   opacity: 0;
   transform: translateY(20px);
}

.starfield-fade-enter-active {
   opacity: 1;
   transform: translateY(0);
   transition: opacity 1s, transform 1s;
}

.starfield-fade-exit {
   opacity: 1;
   transform: translateY(0);
}

.starfield-fade-exit-active {
   opacity: 0;
   transform: translateY(-20px);
   transition: opacity 1s, transform 1s;
}

.starfield-logo {
   font-size: 2.5rem;
   font-weight: bold;
   text-transform: uppercase;
   margin-bottom: 20px;
   animation: logo-pulse 1.5s infinite alternate;
}

@keyframes logo-pulse {
   from {
      transform: scale(1);
   }

   to {
      transform: scale(1.05);
   }
}

.starfield-large-title {
   font-size: 2.5rem;
   margin: 20px 0;
   text-transform: capitalize;
}

.starfield-cta-button {
   background-color: #007bff;
   color: white;
   border: none;
   padding: 10px 20px;
   font-size: 1.2rem;
   cursor: pointer;
   border-radius: 5px;
   transition: background-color 0.3s ease, transform 0.3s ease;
}

.starfield-cta-button:hover {
   background-color: #0056b3;
   transform: scale(1.1);
}